@import "_bootstrap.scss";

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTa-j2U0lmluP9RWlSytm3ho.woff2) format('woff2');
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}
/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTZX5f-9o1vgP2EXwfjgl7AY.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTT0LW-43aMEzIO6XUTLjad8.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTegdm0LZdjqr5-oayXSOefg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url(https://fonts.gstatic.com/s/opensans/v13/K88pR3goAWT7BTt32Z01mxJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}
/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url(https://fonts.gstatic.com/s/opensans/v13/RjgO7rYTmqiVp7vzi-Q5URJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url(https://fonts.gstatic.com/s/opensans/v13/u-WUoqrET9fUeobQW7jkRRJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url(https://fonts.gstatic.com/s/opensans/v13/cJZKeOuBrn4kERxqtaUH3VtXRa8TVwTICgirnJhmVJw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSq-j2U0lmluP9RWlSytm3ho.woff2) format('woff2');
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}
/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSpX5f-9o1vgP2EXwfjgl7AY.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSj0LW-43aMEzIO6XUTLjad8.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSugdm0LZdjqr5-oayXSOefg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzK-j2U0lmluP9RWlSytm3ho.woff2) format('woff2');
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}
/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzJX5f-9o1vgP2EXwfjgl7AY.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzD0LW-43aMEzIO6XUTLjad8.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzOgdm0LZdjqr5-oayXSOefg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Open Sans Italic'), local('OpenSans-Italic'), url(https://fonts.gstatic.com/s/opensans/v13/xjAJXh38I15wypJXxuGMBjTOQ_MqJVwkKsUn0wKzc2I.woff2) format('woff2');
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}
/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Open Sans Italic'), local('OpenSans-Italic'), url(https://fonts.gstatic.com/s/opensans/v13/xjAJXh38I15wypJXxuGMBjUj_cnvWIuuBMVgbX098Mw.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Open Sans Italic'), local('OpenSans-Italic'), url(https://fonts.gstatic.com/s/opensans/v13/xjAJXh38I15wypJXxuGMBiYE0-AqJ3nfInTTiDXDjU4.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Open Sans Italic'), local('OpenSans-Italic'), url(https://fonts.gstatic.com/s/opensans/v13/xjAJXh38I15wypJXxuGMBo4P5ICox8Kq3LLUNMylGO4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@media screen and (max-width: 1230px) {
  section#social-networks-bar {
    display: none !important;
  }
}

// colours
$purple: #9976b0;
$lightpurple: #c9c3df;
$lime: #d8e252;
$brand: #f05d7a;
$orange: #f7921c;
$green: #178a6e;
$grey: #e3dce7;

$stars-yellow: #fde16d;

$facebook: #3b5998;
$twitter: #00aced;
$googlep: #dd4b39;
$youtube: #e02a26;
$pinterest: #cb2027;
$flickr: #ff0084;
$tumblr: #32506d; //yok
$vk: #45668e;
$vimeo: #aad450;
$foursquare: #00aeef;
$wordpress: #21759b;
$blogspot: #ff9334; //yok
$instagram: #833ab4;

@mixin opacity($level, $level_ms) {
  -moz-opacity: $level;
  -khtml-opacity: $level;
  opacity: $level;
  //-ms-filter:"progid:DXImageTransform.Microsoft.Alpha"(Opacity=$level_ms);
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=$level_ms);
  filter:alpha(opacity=$level_ms);
}

@mixin rotate($degrees) {
   -webkit-transform: rotate($degrees);
   -ms-transform: rotate($degrees);
   transform: rotate($degrees);
}

$font-stack: 'Open Sans', Helvetica, Arial, sans-serif;

// Note old platform bootstrap values
body {
  margin: 0;
}

p {
  margin: 5px 0;
}
// Note end old platform bootstrap values

/* Vendor related styles */

.datepicker.dropdown-menu {
  width: 250px;
  height: 270px;
  button {
    outline: 0;
    border: 0;
  }
  tbody {
    //height: 180px;
    button {
      padding: 6px;
    }
  }
  &.datepicker-mode-1 tbody button, &.datepicker-mode-2 tbody button {
    height: 65px;
  }
}

// general
body {
  font-family: $font-stack;
  color: #333;
}
::selection {
  color: #fafafa;
  background: $brand;
}

a {
  color: $purple;
  font-weight: 600;
  font-size: 13px;
  font-family: $font-stack;

  &:hover, &:focus, &:active {
    color: darken($purple, 10%);
    text-decoration: none;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-stack;

  &.leading {
    color: white;
    text-align: center;
    padding: 15px;
    margin-bottom: 15px;
    small {
      margin-top: 5px;
      display: block;
      color: darken(white, 5%);
    }
    &.green {
      background: $green;
      text-shadow: -1px -1px 0px darken($green, 5%);
      border: 1px solid darken($green, 5%);
    }
    &.purple {
      background: $purple;
      text-shadow: -1px -1px 0px darken($purple, 5%);
      border: 1px solid darken($purple, 5%);
    }
    &.brand {
      background: $brand;
      text-shadow: -1px -1px 0px darken($brand, 5%);
      border: 1px solid darken($brand, 5%);
    }
    &.lime {
      background: $lime;
      text-shadow: -1px -1px 0px darken($lime, 5%);
      border: 1px solid darken($lime, 5%);
      color: inherit;
      small {
        color: inherit;
      }
    }
    &.grey {
      background: $grey;
      border: 1px solid darken($grey, 5%);
      color: inherit;
      small {
        color: inherit;
      }
    }
  }
}

hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 0;
  border-top: 1px solid $grey;

  &.card {
    border-color: lighten($grey, 5%);
  }
}

.page-container {
  min-height: 498px;
}

// notifications
.notification {
  &.brand {
    background: lighten($brand, 10%);
    border: 1px solid lighten($brand, 5%);
    color: white;
    padding: 15px;
  }
  &.green {
    background: lighten($green, 10%);
    border: 1px solid lighten($green, 5%);
    color: white;
    padding: 15px;
  }
  &.purple {
    background: lighten($purple, 10%);
    border: 1px solid lighten($purple, 5%);
    color: white;
    padding: 15px;
  }
  &.lime {
    background: lighten($lime, 10%);
    border: 1px solid lighten($lime, 5%);
    padding: 15px;
  }
  &.grey {
    background: lighten($grey, 10%);
    border: 1px solid lighten($grey, 5%);
    color: white;
    padding: 15px;
  }
}

// utility
.uppercase {
  text-transform: uppercase;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.line-through {
  text-decoration: line-through;
}

.capitalize {
  text-transform: capitalize;
}

.clear {
  clear: both;
}

.no-margin {
  margin: 0 !important;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-right-15 {
  margin-right: 15px;
}

// theme
.theme-green {
  color: $green;
}

.theme-purple {
  color: $purple;
}

.theme-brand {
  color: $brand;
}
div#cart-notification {
  background-color: $lime;
  border-bottom: 1px solid darken($lime, 10%);
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 99;

  h4 {
    font-weight: 300;
    font-size: 14px;
  }
}

// header
div#cookie-law {
  background-color: $lime;
  border-top: 1px solid darken($lime, 10%);
  display: none;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 99;

  h4 {
    font-weight: 300;
    font-size: 14px;
  }
}

div#cart-notification {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

header.top {

  div.container {
    div.header-container {
      @media (min-width: 768px) {
        border-bottom: 1px solid $grey;
        padding-bottom: 15px;
      }
    }
  }

  ul#top-links {
    margin-bottom: 5px;

    li {
      padding: 0 10px;
      border-right: 1px solid $grey;
      line-height: 5px;

      &:last-child {
        border-right: none;
        padding-right: 0;
      }

      a#current_lang, a#current_currency {
        color: darken($grey, 10%);
        &:hover {
          color: darken($grey, 20%);
        }
      }
    }
  }

  div#hero {
    background-color: lighten($grey, 9%);
    box-shadow: 5px 5px 0px lighten($grey, 6%);
    cursor: pointer;

    &:hover {
      @include rotate(358deg);
    }

    h1 {
      font-size: 14px;
      line-height: 24px;
      margin: 0;
      color: $green;
      float: left;
      font-weight: 600;

      i.fa-gift, i.fa-truck {
        color: $purple;
        float: left;
        margin: 8px 5px 0 -5px;
      }

      a {
        font-size: 14px;
        color: inherit;
        text-decoration: underline;
      }
    }
  }

  #search-top {
    margin-bottom: 0;
  }

  #cart-delegate-container {
    padding: 5px 10px;
  }

  #cart {
    float: right;
    color: $purple;
    padding-bottom: 5px;
    margin-bottom: -5px;

    @media (max-width: 768px) {
      margin-top: -20px;
    }
    
    a.cart {
      font-size: 16px;

      small {
        font-size: 80%;
      }

    }

    &:hover {
      section#cart-contents {
        display: block;
      }
    }

    @media (max-width: 768px) {
      section#cart-contents {
        left: 0 !important;
      }
    }

    section#cart-contents {
      position: absolute;
      display: none;
      background: white;
      border: 1px solid $grey;
      padding: 15px 15px 0 15px;
      left: -100px;
      top: 30px;
      right: 0;
      z-index: 3;
      box-shadow: 1px 1px 1px #EEE, -1px 0px 1px #EEE;
      color: #333;

      ul.products {
        list-style-type: none;
        padding: 0;

        .price {
          color: $brand;
          font-weight: 700;
        }

        small {
          color: lighten(#333, 10%);
        }
      }

      .cart-total {
        margin-top: 15px;
        padding: 15px;
        background: lighten($grey, 5%);
        border-top: 1px solid $grey;

        h3.total {
          color: $brand;
          font-weight: 600;
          margin-top: 5px;
          margin-bottom: 0;
        }
      }
    }
  }
}

// categories
h1.category-name, h2.category-name, h3.category-name {
  text-align: center;
  color: $brand;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
}

h2.featured {
  margin-top: 0;
  margin-bottom: 20px;
}

// products 
div.homepage-products {
  margin-top: 10px;
  small {
    
  }
}

li.product {
  position: relative;
  float: left;

  img {
    border: 1px solid $purple !important;
  }

  .details {
    left: 1px !important;
    right: 1px !important;

    .name {
      margin: 0;
    }
  }
}

div.product, li.product {
  margin-bottom: 30px;
  float: left;
  position: relative;
  list-style-type: none;

  img {
    max-width: 100%;
    border: 1px solid $grey;
  }

  div.save {
    position: absolute;
    top: 30px;
    left: 30px;
    padding: 20px;
    color: white;
    background: $green;
    box-shadow: 5px 5px 0px darken($green, 5%);
    @include rotate(345deg);
    &.small {
       padding: 7px;
       top: 10px;
       left: 0;
       box-shadow: 3px 3px 0px darken($green, 3%);
    }
    &.medium {
       padding: 15px;
       left: 5px;
       top: 5px;
    }
  }

  div.details {
    position: absolute;
    bottom: 0;
    right: 0px;
    left: 0px;
    background: rgba(239, 235, 241, 0.8);
    padding: 10px;
    color: #333;

    h3 {
      margin-top: 10px;
    }

    .name {
      text-align: left;
      font-weight: bold;
      text-transform: uppercase;
      color: $brand;
    }
    .price {
      text-align: right;
      font-weight: bold;
      color: $brand;
      small {
        color: $brand;
        text-decoration: line-through;
      }
    }
  }
  a {
    position: relative;
    float: left;
    img {
      max-width: 100%;
    }
  }

  &:hover {
    img {
      @include opacity(0.95, 95);
      border-color: darken($grey, 5%);
    } 
    .details {
      .name, .price {
        color: darken($brand, 5%);
      }
    }
    .save {
      @include rotate(350deg)
    }
  }
}

div.additional-product {
  float: left;
  position: relative;
  margin-bottom: 30px;

  .details {
    left: 0 !important;
    right: 0 !important;
  }
}

div.addToCart {
  width: 100%;
  float: left;
  text-align: center;
  button.addAdditionalProductToCart {
    margin-bottom: 30px;
  }
}

// product page
div#product-img {
  img {
    border: 1px solid $grey;

  }
  div.product-pictures {
    cursor: pointer;
    margin-top: 5px;
    img {
      margin-right: 2px;
    }
  }
  div.save {
    position: absolute;
    top: 30px;
    left: 30px;
    padding: 20px;
    color: white;
    background: $green;
    box-shadow: 5px 5px 0px darken($green, 5%);
    @include rotate(345deg);
    &.small {
       padding: 7px;
       top: 10px;
       left: 0;
       box-shadow: 3px 3px 0px darken($green, 3%);
    }
    &.medium {
       padding: 15px;
       left: 5px;
       top: 5px;
    }
  }

  &:hover {
    img {
      @include opacity(0.95, 95);
      border-color: darken($grey, 5%);
    }
    .save {
      @include rotate(350deg)
    }
  }
}

div#product-contents {
  .name, .price {
    color: $brand;
    margin-top: 0;
    text-transform: uppercase;
    font-weight: 600
  }

  .price {
    small {
      color: $brand;
      text-decoration: line-through;
    }
  }
}

div#product-accessories {
  ul#additions-right {
    list-style-type: none;
    padding: 0;
  }
}

// Reviews
section.review-block {
  margin-bottom: 30px;
  background: lighten($grey, 5%);

  div.stars {
    i {
      font-size: 1.6em;
    }
    margin-top: 5px;
    color: $grey;
    position: relative;
    display: inline-block;
    overflow: hidden;

    .stars-overlay {
      position: absolute;
      color: $purple;
      top: 0;
      left: 0;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .review {
    color: #333;
    font-size: 13px;
    margin-right: -15px;
    padding-right: 45px;
    div.stars {
      .stars-overlay {
        color: $purple;
      }
      i {
        font-size: 1em;
      }
    }

    span.badge-purple {
      position: absolute;
      top: 5px;
      left: 95px;

      @media (max-width: 768px) {
        left: 115px;
      }
    }
  }
}
section#reviews-block {
  margin-bottom: 30px;
  background: lighten($grey, 5%);
  color: white;
  height: 100px;
  
  h3 {
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 400;
    margin-top: 15px;
  }

  div.stars {
    i {
      font-size: 1.6em;
    }
    margin-top: 5px;
    color: $grey;
    position: relative;
    display: inline-block;
    overflow: hidden;

    .stars-overlay {
      position: absolute;
      color: white;
      top: 0;
      left: 0;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  #rating {
    background: lighten($purple, 15%);
    margin-left: 15px;
    padding-bottom: 15px;
    height: 100px;
    h3 {
      text-shadow: -1px -1px 0px lighten($purple, 10%);
    }
  }

  #average {
    background: $purple;
    height: 100px;
    h3 {
      text-shadow: -1px -1px 0px darken($purple, 5%);
    }
  }

  #latest-review {
    color: #333;
    font-size: 13px;
    margin-right: -15px;
    padding-right: 45px;
    div.stars {
      .stars-overlay {
        color: $purple;
      }
      i {
        font-size: 1em;
      }
    }

    span.badge-purple {
      position: absolute;
      top: 5px;
      left: 95px;
    }
  }
}

// footer 
footer {
  background-color: $grey;
  color: darken($purple, 5%);

  &.footer-top {
    background: $green;
    border-top: 1px solid darken($grey, 5%);
  }

  &.footer-middle {
    padding: 15px 0;
    ul {
      list-style-type: none;
      padding: 0;

      &#social-networks {
        float: right;
        margin-top: 15px;
        li {
          h4 {
            font-weight: 300;
          }
          &.facebook {
            a {
              color: $facebook;
              &:hover {
                color: darken($facebook, 5%);
              }
            }
          }
          &.instagram {
            a {
              color: $instagram;
              &:hover {
                color: darken($instagram, 5%);
              }
            }
          }
          &.twitter {
            a {
              color: $twitter;
              &:hover {
                color: darken($twitter, 5%);
              }
            }
          }
          &.google-plus {
            a {
              color: $googlep;
              &:hover {
                color: darken($googlep, 5%);
              }
            }
          }
          &.youtube {
            a {
              color: $youtube;
              &:hover {
                color: darken($youtube, 5%);
              }
            }
          }
          &.vimeo {
            a {
              color: $vimeo;
              &:hover {
                color: darken($vimeo, 5%);
              }
            }
          }
          &.pinterest {
            a {
              color: $pinterest;
              &:hover {
                color: darken($pinterest, 5%);
              }
            }
          }
          &.vk {
            a {
              color: $vk;
              &:hover {
                color: darken($vk, 5%);
              }
            }
          }
          &.wordpress {
            a {
              color: $wordpress;
              &:hover {
                color: darken($wordpress, 5%);
              }
            }
          }
          &.blogspot {
            a {
              color: $blogspot;
              &:hover {
                color: darken($blogspot, 5%);
              }
            }
          }
        }
      }
      li {
        a {
          font-weight: 400;
        }
      }
    }

    div#delivery-list {
      a {
        font-weight: 400 !important;
        font-size: 12px;
        padding: 0px 5px 0px;
        border-right: 1px solid lighten($purple, 5%);
        &:last-child {
          border: none;
        }
        &:first-child {
          padding-left: 0px;
        }
      }
    }
  }

  &.footer-bottom {
    background-color: $purple;
    border-top: 1px solid darken($purple, 5%);
    color: white;
    padding: 15px;
    font-size: 11px;

    a {
      color: white;
      font-weight: 600;
      font-size: 11px;
    }
  }
}

// order pages
i#citySelectSpinner {
  display: none;
}

div#delivery-cities {
  position: relative;
}

ul#delivery-cities {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 3;
  padding: 0;
  list-style-type: none;
  background: #f4f4f4;
  border: 1px solid darken(#f4f4f4, 5%);
  border-top: none;
  display: none;
  cursor: pointer;

  li {
    padding: 15px;
    &:hover {
      background: $purple;
      color: white !important;

      small.color-green, small.color-brand {
        color: inherit !important;
      }
    }

    &.no-delivery {
      cursor: not-allowed;
    }
  }
}

// cards
.card {
  margin-top: 10px;
  margin-bottom: 20px;
  background: lighten($grey, 5%);
  padding: 0 !important;
  &.shadow {
    box-shadow: 0px 0px 5px #BBB;
  }

  header {
    padding: 30px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    h1,h2,h3,h4,h5,h6 {
      margin: 0;
    }
    &.brand {
      background: $brand;
      color: white;
      border-top: 5px solid darken($brand, 5%);

      ::selection {
        color: #fafafa;
        background: $green;
      }
      .title {
        text-shadow: -1px -1px 0px darken($brand, 5%);
      }
      small.no-delivery {
        color: white;
        font-size: 50%;
      }
    }
    &.purple {
      background: $purple;
      color: white;
      border-top: 5px solid darken($purple, 5%);
      .title {
        text-shadow: -1px -1px 0px darken($purple, 5%);
      }
    }
    &.green {
      background: $green;
      color: white;
      border-top: 5px solid darken($green, 5%);
      .title {
        text-shadow: -1px -1px 0px darken($green, 5%);
      }
    }
    &.lime {
      background: $lime;
      color: inherit;
      border-top: 5px solid darken($lime, 5%);
    }
  }

  section.body {
    padding: 15px;
    small {
      color: #555;
    }
  }

  footer {
    padding: 15px;
    background: inherit;
  }
}

ul.blocks {
  list-style-type: none;
  padding: 0;
  margin-top: 15px;

  li {
    height: 200px;
    color: white;
    overflow: hidden;
    margin-bottom: 30px;
    a {
      color: white;
      text-align: center;
      display:block;
      padding-bottom: 30px;
      i.fa {
        padding-top: 50px;
        padding-bottom: 35px;
        display:block;
      }
    }
  }

  &.purple {
    li {
      background: $purple;
      border: darken($purple, 5%);
      &:hover {
        background: darken($purple, 5%);
        border-color: darken($purple, 10%);
      }
    }
  }

  &.brand {
    li {
      background: $brand;
      border: darken($brand, 5%);
      &:hover {
        background: darken($brand, 5%);
        border-color: darken($brand, 10%);
      }
    }
  }

  &.green {
    li {
      background: $green;
      border: darken($green, 5%);
      &:hover {
        background: darken($green, 5%);
        border-color: darken($green, 10%);
      }
    }
  }

  &.lime {
    li {
      color: inherit;
      background: $lime;
      border: darken($lime, 5%);
      a {
        color: inherit;
      }
      &:hover {
        background: darken($lime, 5%);
        border-color: darken($lime, 10%);
      }
    }
  }

  &.grey {
    li {
      color: inherit;
      background: $grey;
      border: darken($grey, 5%);
      a {
        color: inherit;
      }
      &:hover {
        background: darken($grey, 5%);
        border-color: darken($grey, 10%);
      }
    }
  }
}

// inputs
div.bunches {
  div.input-group {
    span.input-group-addon {
      border-radius: 0;
      background-color: $grey;
      border-color: darken($grey, 10%);
      color: $purple;
    }
  }

  input, select, textarea {
    border-radius: 0;
    border-color: darken($grey, 10%);
    background-color: #f4f4f4;

    &:focus {
      border-color: $purple;
      outline: 0;
      -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(153,118,176, 0.6) ;
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(153,118,176, 0.6) 
    }
  }

  label {
    font-weight: 600;
  }
}

.modal-content {
  border-radius: 0 !important;
}

// buttons
.btn {
  border-radius: 0;
  &:hover, &:active, &:focus {
    color: white;
  }

  &.btn-default {
    &:hover, &:focus, &:active {
      color: #333;
    }
  }
}
.btn-purple {
  background-color: $purple;
  color: white;
  border: 1px solid darken($purple, 10%);

  &:hover {
    background-color: darken($purple, 5%);
  }
}

.btn-green {
  background-color: $green;
  color: white;
  border: 1px solid darken($green, 10%);

  &:hover {
    background-color: darken($green, 5%);
  }
}

.btn-lime {
  background-color: $lime;
  color: white;
  border: 1px solid darken($lime, 10%);

  &:hover {
    background-color: darken($lime, 5%);
  }
}

.btn-brand {
  background-color: $brand;
  color: white;
  border: 1px solid darken($brand, 10%);

  &:hover {
    background-color: darken($brand, 5%);
  }
}

// badges
.badge-purple {
  background-color: $purple;
}

// labels 
.label {
  &.label-green {
    background-color: $green;
  }
  &.label-brand {
    background-color: $brand;
  }
  &.label-purple {
    background-color: $purple;
  }
  &.label-lime {
    background-color: $lime;
  }
  &.label-grey {
    background-color: $grey;
  }
}

// text-color 
.color-brand {
  color: $brand !important;
}

.color-purple {
  color: $purple;
}

.color-green {
  color: $green !important;
}

.color-lime {
  color: $lime;
}

.color-grey {
  color: #999;
} 

.color-initial {
  color: #333;
}

// nav pills
.nav-pills {
  &.purple {
    li {
      &.active {
        a {
          background: $purple;
        }
        &:hover {
          a {
            background: darken($purple, 5%);
          }
        }
      }
      &:hover {
        a {
         background: $grey;
        }
      }
    }
  }
  &.green {
    li.active {
      a {
        background: $green;
      }
    }
  }
  &.brand {
    li.active {
      a {
        background: $brand;
      }
    }
  }
}

// tables
table {
  @media (max-width: 768px) {
    display: block;
    overflow-x: auto;
  }
  thead, th {
    font-weight: 600;
    border-bottom: 1px solid darken($grey, 5%) !important;
  }
  tr.order-details {
    background: lighten($grey, 5%);
    display: none;
    strong {
      font-weight: 600;
    }
    em {
      font-weight: 300;
    }
  }
}

// alerts
.alert {
  color: white;
  border-radius: 0;
  &.brand {
    background: $brand;
    border: 1px solid darken($brand, 5%);
    text-shadow: -1px -1px 0px $brand;
    button.close {
      color: #fafafa !important;
    }
  }
  &.green {
    background: $green;
    border: 1px solid darken($green, 5%);
    text-shadow: -1px -1px 0px $green;
    button.close {
      color: #fafafa !important;
    }
  }
  &.purple {
    background: $purple;
    border: 1px solid darken($purple, 5%);
    text-shadow: -1px -1px 0px $purple;
  }
  &.lime {
    background: $lime;
    border: 1px solid darken($lime, 5%);
    color: inherit;
  }
  &.grey {
    background: $grey;
    border: 1px solid darken($grey, 5%);
    color: inherit;
  }
}

#cart-right {
  strong {
    font-weight: 600;
  }
}

// kitchen sink
.rating-container {
  color: darken($grey, 5%) !important;
  .rating-stars {
    color: $purple !important;
  }
}

.nav.nav-pills li a {
  padding: 15px !important;
}

div#filtering {
  display: none;
  i {
    color: $grey;
  }
}
div#overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: none;
  background: rgba(255, 255, 255, 0);
}
.filter-container {
  margin-bottom: 10px;
  position: relative;
  .filter {
    display: none;
    position: absolute;
    z-index: 2;
    left: -15px;
    top: 35px;
    min-height: 200px;
    min-width: 300px;
    background: white;
    border: 1px solid $grey;
    box-shadow: 1px 1px 1px #EEE, -1px 0px 1px #EEE;
    padding: 15px;
  }
}

.slider {
  //display: block !important;
}

.slider-handle {
  background-color: $purple !important;
  background-image: none !important;
}
.slider-track {
  background-color: $grey !important;
  box-shadow: none !important;
  background-image: none !important;
}
.slider-selection {
  background-color: lighten($purple, 15%) !important;
  box-shadow: none !important;
  background-image: none !important;
}

// SMALL
@media (min-width: 768px) and (max-width: 992px) {
  .margin-sm-left-15 {
    margin-left: 15px;
  }
}

// XSMALL
@media (max-width: 768px) {
  img.align-center-xs {
    display: block;
    margin: auto;
  }

  .btn-expand-xs {
    width: 100%
  }

  .col-xs-padding-30 {
    padding-left: 30px !important;
  }
}

.disabled-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: default;
}
